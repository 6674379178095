<style>
.no-footer.dataTable th:before {
    height: 6px;
    width: 6px;
    background: transparent;
    color: transparent;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    opacity: 1 !important;
    transform: rotate(-45deg);
}
.no-footer.dataTable th:after {
    height: 6px;
    width: 6px;
    background: transparent;
    color: transparent;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    opacity: 1 !important;
    transform: rotate(135deg);
}
</style>
<template>
  <div class="profile-data-wrapper financial-graphs earnings" v-show="!loading">
    <div class="profile-options">
      <div class="row">
        <div class="col">
          <router-link class="btn primary-btn" :to="{ name: 'FinancialGraphs' }"
            ><font-awesome-icon :icon="['fas', 'arrow-left']"
          /></router-link>
        </div>
        <div class="col flex-grow-1 d-flex justify-content-end">
            <div class="search-container">
                <input
                    class="form-control--filled"
                    name="search"
                    @keyup="performSearch"
                    v-model.trim="searchquery"
                    placeholder="Search By Earnings and Client Name"
                    type="text"
                />
                <button type="submit">
                    <span class="lnr lnr-magnifier"></span>
                </button>
            </div>
        </div>
        <div class="col flex-grow-1 d-flex justify-content-end">
          <VueDatePicker v-model="filters.date" @update:model-value="getReports" :transitions="false" range multi-calendars
          :enable-time-picker="false" ></VueDatePicker>
        </div>
        <!-- <div class="col">
          <Select2
            id="filter-connect-types"
            v-model="filters.client.value"
            :options="filters.client.options"
            class="select2-box"
            :resetOptions="false"
            :placeholder="`Connect Type`"
            @select="getConnects()"
          />
        </div> -->
      
      </div>
    </div>
    
    <div class="custom-table no-arrows">
      <table id="datatable">
        <thead>
          <tr>
            <th>Ranking</th>
            <th>Earnings($)</th>
            <th>Clients</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(earning, index) in earningData" :key="index">
            <td>{{ earning.number }}</td>
            <td>{{ earning.Amount }}</td>
            <td>{{ earning.Team }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="profile-footer d-flex justify-content-end">
      <button class="primary-btn" type="button" @click="downloadCsv()">
        <font-awesome-icon :icon="['fa', 'download']" /> Download
      </button>
    </div>
  </div>
</template>
<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronRight,
  faDownload,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
library.add(faChevronRight, faDownload, faArrowLeft);

import FinancialReportsService from "../services/financialReports.service.js";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.html5.js";
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.html5.js";
//import Select2 from "../components/Select2.vue";
import '@vuepic/vue-datepicker/dist/main.css'
import common from "../mixins/common";

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import moment from 'moment'



export default {
  components: {FontAwesomeIcon, VueDatePicker },
  mixins: [common],
  data() {
    return {
      loading: false,
      filters: {
        date: new Date(),
      },
      earningData: [],
      earningGauge: {
        options: {},
      },
      sideModal: {
        connect: {
          add: {
            show: false,
          },
        },
      },
    };
  },
  async mounted() {
    
    const date = new Date();
    const toDate = new Date();
    const fromDate = new Date(date.setMonth(date.getMonth() - 6));
    console.log('to Date',toDate +'--------'+fromDate);
    await this.getReports(fromDate,toDate);
    this.filters.date = [fromDate, toDate];
    this.loading = false;
  },
  methods: {

    formatDate () {
      const date = new Date();
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `Selected date is ${day}/${month}/${year}`;
    },

    async handleDate(modelData) {
      const fromDate = moment(modelData[0]).format('YYYY-MM-DD');
      const toDate = moment(modelData[1]).format('YYYY-MM-DD');
      this.getReports(fromDate, toDate);
    },
    async getReports(fromDate, toDate) {
      let vm = this;
      try {
        vm.$store.commit("loadingStatus", true);
        const response = await FinancialReportsService.get(
          fromDate, toDate
        );

        console.log('response',response.clients);
        vm.earningData = response.clients;

        var dT = $("#datatable").DataTable();
          dT.destroy();

        vm.$nextTick(function () {
          $("#datatable").DataTable({
            dom: "Bfrtip",
            buttons: [
              {
                extend: "csv",
                filename: "Finanical Reports "+vm.filters,
                extension: ".csv",
              },
            ],
            iDisplayLength: 20,
            bLengthChange: false,
            /* Disable initial sort */
            aaSorting: [],
            info: false,
          });
          $(".dt-buttons, .dataTables_filter").hide();
        });
        vm.$store.commit("loadingStatus", false);
      } catch (error) {
        const message = vm.errorMessage(error);
        vm.toastMessage(message, "error");
        vm.$store.commit("loadingStatus", false);
      }
    },
    downloadCsv: async function () {
      let vm = this;
      await vm.$store.dispatch("getUserDetails").then(response => {
          if(response.is_active) $(".buttons-csv").click();
      });
      //$(".buttons-csv").click();
    },
    closeSideModal() {
      document.body.classList.remove("lmodal-open");
      setTimeout(() => {
        this.sideModal.connect.add.show = false;
      }, 300);
    },
    performSearch: function () {
      this.performCustomSearch();
    },
    performCustomSearch: function () {
      var oTable = $("#datatable").dataTable();
      var searchText = " ";
      if (this.searchquery != null) {
        searchText += this.searchquery;
      }
      
      //$('#skill_'+this.profileType).prop('checked',true);
     /* if (this.skill.length > 0) {
        let test = this.skill;
        let skills = test.join("|");
        searchText += " "+skills;
      }
      console.log('searchText---',searchText);*/
      oTable.fnFilter(searchText);
    },
    async openSideModal(action) {
      let vm = this;

      await vm.$store.dispatch("getUserDetails");
      if (
        vm.userPermissions &&
        vm.userPermissions.financial_graphs &&
        vm.userPermissions.financial_graphs.length > 0 &&
        vm.userPermissions.financial_graphs.includes("View")
      ) {
        if (
          action === "addConnect" &&
          vm.userPermissions.financial_graphs.includes("Add")
        ) {
          vm.sideModal.connect.add.show = true;
        }
        setTimeout(() => {
          document.body.classList.add("lmodal-open");
        }, 300);
      }
    },
  },
};
</script>
<style>
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled{display:none;}
</style>